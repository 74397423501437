/**
 * @prettier
 */

import { isMobile, isTablet } from 'react-device-detect';
import apiService from '../../services/api/api';
import DataDogService from '../../services/shared/datadogService';
import logService from '../../services/shared/logService';
import { history } from '../../store';

// Navigate to the next page based on page and state
export function navigateNext(page, state, props, params) {
    let next_page = '/';
    let finalize = false;
    switch (page) {
        case 'app_entry':
            next_page = nextFromAppEntry(state);
            finalize = true;
            break;

        case 'start_screen':
            next_page = firstUncompletedStage(state);
            finalize = true;
            break;

        case 're_verification_start':
            next_page = nextFromReverificationStart(state, props);
            finalize = true;
            break;

        case 'e_verification':
            next_page = nextFromEVerification(state, props);
            break;

        case 'document_selection':
            next_page = nextFromDocumentSelection(state, props);
            break;

        case 'instructions':
            next_page = nextFromInstructions(props);
            break;

        case 'capture_photo':
            next_page = nextFromCapturePhoto(props);
            break;

        case 'document_capture':
            next_page = nextFromDocumentCapture(state, props);
            break;

        case 'document_review':
            next_page = nextFromDocumentReview(state, props);
            finalize = true;
            break;

        case 'proof_of_address':
            next_page = nextFromProofOfAddress(state, props);
            finalize = true;
            break;

        case 'passive_liveness':
            next_page = nextFromPassiveLiveness(props);
            finalize = true;
            break;
        default:
            return;
    }

    if (next_page === '/success' && finalize === true) {
        finalizeThenSuccess(params);
    } else {
        history.push({
            pathname: next_page,
            search: params,
            state: props,
        });
    }

    return dispatch => {
        dispatch({ type: 'NAVIGATED' });
    };
}

// Check status on load
export function checkLoadState(page, state, props) {
    switch (page) {
        case 'e_verification':
            eVerificationLoadState(state, props);
            break;
        case 'document_selection':
            documentSelectionLoadState(state, props);
            break;
        default:
            return;
    }
}

// Main order of screens
export function firstUncompletedStage(state) {
    if (
        state.screens.e_verification.enabled &&
        !state.submissionState.e_verification.completed
    ) {
        return '/e_verification';
    } else if (state.screens.mobile_handoff.enabled && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (
        state.screens.document_upload.enabled &&
        !state.submissionState.document_upload.completed
    ) {
        return '/select';
    } else if (
        state.submissionState.document_upload.completed &&
        !state.submissionState.poa_upload.completed &&
        state.screens.poa_upload.enabled &&
        isMobile
    ) {
        return '/capture/proof_of_address/';
    } else if (
        state.screens.passive_liveness.enabled &&
        !state.submissionState.passive_liveness.completed &&
        (isMobile || isTablet)
    ) {
        return '/capture/selfie';
    } else {
        return '/success';
    }
}

export function navigateBack(page, state, props, params) {
    if (page === 'document_capture') {
        let next_page = backFromDocumentCapture(state, props);
        history.push({
            pathname: next_page,
            search: params,
            state: props,
        });
    } else {
        goBack();
    }
}

// Go back
export function goBack() {
    history.goBack();
}

export function clearError(context) {
    // Clear any errors
    context.setState({
        navigation: {
            action: 'load',
            props: null,
        },
    });
}

// Finalize then Success Page
export function finalizeThenSuccess(params) {
    apiService
        .postFinalizeSubmission()
        .then(response => {
            history.push('/success');
        })
        .catch(err => {
            // Catch and log error
            DataDogService.createError('Unable to finalize submission.');
            logService.error(err);
            history.push({
                pathname: '/error/default',
                search: params,
                state: { type: 'finalize' },
            });
        });
}

// Next Page
export function nextFromAppEntry(state) {
    if (state.screens.passive_liveness?.context === 're_verification') {
        return '/re_verification';
    } else if (
        state.screens.start_screen.enabled &&
        state.screens.passive_liveness?.context !== 're_verification'
    ) {
        return '/start';
    } else {
        return firstUncompletedStage(state);
    }
}

// Next Page
export function nextFromStart(state) {
    return firstUncompletedStage(state);
}

// There is no liveness on the desktop flow.
// To prevent liveness from showing go straight to finalise.
// Change this to an error screen?
export function nextFromReverificationStart(state) {
    if (state.screens.mobile_handoff.enabled && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (
        !state.screens.mobile_handoff.enabled &&
        !isMobile &&
        !isTablet
    ) {
        return '/success';
    } else {
        return '/capture/selfie';
    }
}

export function nextFromEVerification(state, props) {
    if (
        state.submissionState.e_verification.result === 'pass' &&
        props.eVerificationMode === 'document_fallback'
    ) {
        return '/success';
    } else if (state.screens.mobile_handoff.enabled && !isMobile && !isTablet) {
        return '/mobile_handoff';
    } else if (state.screens.document_upload.enabled) {
        return '/select';
    }
    return '/e_verification';
}

export function nextFromDocumentSelection(state, props) {
    if (!isMobile && !isTablet && !props.isIdPalCamera) {
        return '/capture/camera';
    } else if (!isMobile && !isTablet && props.isIdPalCamera) {
        return '/capture/idpal_camera/';
    } else {
        return '/instruction/' + props.documentId + '/1';
    }
}

export function nextFromInstructions(props) {
    if (props.isRetry) {
        return '/capture/photo';
    } else if (props.isIdPalCamera && !props.isRetry) {
        return '/capture/idpal_camera/';
    } else {
        return '/capture/camera';
    }
}

export function nextFromDocumentCapture(state, props) {
    if (state.submissionState.document_upload.completed) {
        return firstUncompletedStage(state);
    } else if (props.isRetry) {
        return '/capture/photo';
    } else {
        return '/photo/confirm';
    }
}

export function nextFromCapturePhoto(props) {
    if (props.isIdPalCamera) {
        return '/capture/idpal_camera/';
    } else {
        return '/capture/camera';
    }
}

export function nextFromDocumentReview(state, props) {
    if (state.submissionState.document_upload.completed) {
        if (state.screens.poa_upload.enabled && isMobile) {
            return firstUncompletedStage(state);
        } else if (state.screens.passive_liveness.enabled && isMobile) {
            return '/capture/selfie';
        } else {
            return '/success';
        }
    }

    if (props.sidesLeft === 1) {
        if (isMobile || isTablet) {
            return '/instruction/' + props.documentId + '/2';
        } else {
            return '/capture/photo';
        }
    }

    if (props.isRetry) {
        if (isMobile || isTablet) {
            return '/instruction/' + props.documentId + '/1';
        } else {
            return '/capture/photo';
        }
    }
}

export function nextFromProofOfAddress(state, props) {
    if (state.screens.passive_liveness.enabled && isMobile) {
        return '/capture/selfie';
    } else {
        return '/success';
    }
}

export function nextFromPassiveLiveness(props) {
    if (props && props.fallback) {
        return '/capture/idpal_selfie';
    } else {
        return '/success';
    }
}

export function backFromDocumentCapture(state, props) {
    if (!isMobile && !isTablet) {
        if (props.sidesLeft === 1) {
            return '/capture/photo';
        } else {
            return '/select';
        }
    } else {
        if (props.sidesLeft === 1) {
            return '/instruction/' + props.documentId + '/2';
        } else {
            return '/instruction/' + props.documentId + '/1';
        }
    }
}

// Load Checks
export function eVerificationLoadState(state, props) {
    if (state.submissionState.e_verification.completed === 1) {
        navigateNext('e_verification', state, props);
    }
}

export function documentSelectionLoadState(state, props) {
    if (!props.instanceID) {
        history.push('/');
    }
}
